import { envIs, looseHrefSchema, requiredIf } from '@purposity/utils'
import { createEnv } from '@t3-oss/env-nextjs'
import * as z from 'zod'
import { PURPOSITY_ENV } from './constants.mjs'

export const env = createEnv({
  emptyStringAsUndefined: true,
  server: {
    ADMIN_API_SECRET: z.string(),
    API_ORIGIN: z.string(),
    CLERK_JWT_KEY: z.string(),
    CLERK_SECRET_KEY: z.string().refine((v) => v.startsWith('sk_')),
    /** @deprecated remove after transition to `purposity-native` doppler is complete  */
    DOPPLER_PROJECT: z.literal('purposity-native'),
    EDGE_CONFIG: z.string().min(1),
    GEOCODIO_API_KEY: z.string(),
    HASURA_WEBHOOK_SECRET: z.string(),
    HOOKDECK_SECRET: z.string(),
    LEGACY_JWT_KEY: z.string(),
    SECRET_KEY: z.string(),
    SENTRY_AUTH_TOKEN: z.string().catch(requiredIf(envIs(['production'])(PURPOSITY_ENV), '')),
    STRIPE_SECRET_KEY: z.string(),
    UPSTASH_REDIS_REST_TOKEN: z.string(),
    UPSTASH_REDIS_REST_URL: z.string(),
    /** @turbo passthrough */
    VERCEL_URL: looseHrefSchema.optional().catch(requiredIf(envIs(['production', 'preview'])(process.env.VERCEL_ENV), `http://localhost:${process.env.PORT ?? 3020}`)),
  },
  client: {
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().startsWith('pk_'),
  },
  runtimeEnv: {
    ADMIN_API_SECRET: process.env.ADMIN_API_SECRET,
    API_ORIGIN: process.env.API_ORIGIN,
    CLERK_JWT_KEY: process.env.CLERK_JWT_KEY,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    DOPPLER_PROJECT: process.env.DOPPLER_PROJECT,
    EDGE_CONFIG: process.env.EDGE_CONFIG,
    GEOCODIO_API_KEY: process.env.GEOCODIO_API_KEY,
    HASURA_WEBHOOK_SECRET: process.env.HASURA_WEBHOOK_SECRET,
    HOOKDECK_SECRET: process.env.HOOKDECK_SECRET,
    LEGACY_JWT_KEY: process.env.LEGACY_JWT_KEY,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    SECRET_KEY: process.env.SECRET_KEY,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
    UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
    VERCEL_URL: process.env.VERCEL_URL,
  },

  onValidationError(n) {
    throw (console.error('\u274C Invalid environment variables:', n.flatten().fieldErrors), new Error(`Invalid environment variables: ${Object.keys(n.flatten().fieldErrors).join(', ')}`))
  },
  skipValidation:
    // !!process.env.CI ||
    process.env.SKIP_ENV_VALIDATION === 'true' || process.env.npm_lifecycle_event === 'lint',
})
